@import '../../styles//abstracts/variables';
@import '../../styles/abstracts/mixins';

.sv_main.sv_main {
  .sv_container {
    .progress {
      width: 95%;
      border-radius: 5px;
      background-color: $gray-300;

      span {
        display: none;
      }
    }

    .sv_row {
      h5 {
        margin-bottom: 15px;
        font-size: 1.3rem;
      }

      .sv_qstn {
        h5 span:last-child {
          white-space: pre-line;
        }

        .small {
          font-size: 14px;
          margin-top: -10px;
          margin-bottom: 10px;
        }

        .btn-group {
          flex-wrap: wrap;
          // display: flex;

          .btn {
            border-width: 0;
            border-right: 1px solid $white;
            border-left: 1px solid $white;
            padding: 0.75rem;
            font-size: 1.2rem;
            min-width: 3rem;

            &.btn-secondary {
              background-color: $gray-500;
            }
            &.btn-secondary:not(:disabled):not(.disabled).active {
              background-color: $primary;
            }
          }
          .btn:last-child {
            border-right-width: 0;
          }
          .btn:first-child {
            border-left-width: 0;
          }
        }

        .sv_qcbx,
        .sv_qcbc {
          label {
            display: flex;
            align-items: center;
            line-height: initial !important;
          }
        }
      }

      .sv_qcbx {
        input {
          margin-right: 6px;
        }
        label {
          font-size: 1.2rem;
        }

        .sv-q-col-1 {
          margin-bottom: 5px;
        }
        .sv-q-col-1:last-child {
          margin-bottom: 0;
        }
      }

      .sv_qcbc .radio {
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }

        label {
          font-size: 1.2rem;

          > span:last-child {
            margin-left: 6px;

            span {
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }

  .sv_next_btn,
  .sv_prev_btn,
  .sv_complete_btn {
    font-size: 1.2rem;
    padding: 0.75rem 1.2rem;
  }

  .sv_next_btn,
  .sv_complete_btn {
    background-color: $primary;
    color: $white;
  }
}

@include media-breakpoint-down(xs) {
  .ui.container {
    padding: 0 !important;
    margin: 0 !important;
  }
  .sv_main.sv_main {
    .sv_qstn {
      .btn-group {
        width: 100%;

        .btn {
          flex: 1 1 auto;
        }
      }
    }
  }
}

.resmed-theme .sv_main.sv_main {
  .sv_container {
    .form-control:focus {
      border-color: rgb(147, 214, 255);
      box-shadow: 0 0 0 0.2rem rgba(75, 170, 230, 0.25);
    }
    .progress-bar {
      background-color: #0077c0;
    }
    .sv_row {
      .sv_qstn {
        .btn-group {
          .btn {
            &.btn-secondary:not(:disabled):not(.disabled).active {
              background-color: #0077c0;
            }
          }
        }
      }
    }
  }

  .sv_next_btn,
  .sv_complete_btn {
    background-color: #0077c0;
    color: $white;
  }
}

.sisu-main-container {
  font-family: 'Inter';
}

.sisu-header-container {
  background: #b6ded7;
  padding-top: 18px;
  padding-left: 18px;
  padding-bottom: 2px;
  padding-right: 18px;

  .disclaimer-icon {
    margin-right: 10px;
  }
}

.sisu-sub-header-container {
  padding: 26px;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  background: #e6f2ef;
  border-radius: 10.2px;
  .icon {
    margin-bottom: 10px;
    width: 30px;
    height: 30px;
  }
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.408px;
  color: #06705a;
}

.flexBoxSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sisu-theme {
  .header-container {
    .img-container {
      background: #b6ded7;
      margin-bottom: 18px;
    }
    .name {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #00200a;
      margin-bottom: 20px;
    }
    .description {
      font-weight: 600;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: -0.408px;
      color: #06705a;
      margin-right: 40px;
    }
    .disclaimer {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: -0.408px;
      color: #06705a;
    }
  }
}
.mb10 {
  margin-bottom: 20px;
}
.loading-owl {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .nightowl-img-container {
    img {
      height: 120px;
    }
  }
}
.nightowl-img-container {
  animation: walk 2s ease-in-out infinite;
}
.nightowl-img-container img {
  height: 200px;
}
@keyframes walk {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0);
  }
}
.sisu-theme .sv_main.sv_main {
  .sv_container {
    font-family: 'Inter';
    .sv_header {
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #00200a;
      background-color: #b6ded7;
      padding-left: 30px;
      padding-top: 20px;
    }
    .sv_body {
      .sv_page_title {
        margin-top: 40;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        color: #00200a;
        background: '#06705A';
        padding: 18px 33px;
        .sv_header__text {
          width: 254px;
        }
      }
      .sv_p_root > div.sv_row,
      .sv_nav {
        padding: 18px 20px;
      }
      h5 {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-bottom: 16px !important;
      }
      // .sv_p_root > .sv_row:first-child {
      //   font-style: normal;
      //   font-weight: 600;
      //   font-size: 16px;
      //   line-height: 23px;
      //   letter-spacing: -0.408px;
      //   color: #06705a;
      //   padding: 25px;
      //   background: #eaf6f3;
      //   border-radius: 10px;
      //   b {
      //     padding: 0 !important;
      //   }
      // }

      .sv_row {
        .sv_qstn {
          padding-left: 0px !important;
          p {
            font-weight: 500;
            font-style: normal !important;
            font-size: 20px;
            line-height: 137.2%;
            color: #1a967d;
          }
          div > b {
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            letter-spacing: -0.408px;
            color: #06705a;
            background: #eaf6f3;
            border-radius: 10px;
            margin: 4px 0px;
            margin-top: 20px;
            padding: 20px;
            display: inline-block;
            margin-bottom: 40px;
          }
          .sv_q_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 137.2%;
            color: #1a967d;
            margin: 12px 30px;
          }
          .sv_select_wrapper {
            .sv_q_dropdown_control {
              width: 100%;
              height: 3rem;
              background: #f6f6f6;
              opacity: 0.5;
              border: 1px solid $black;
              padding-left: 16px;
              border: 1px solid #cacaca;
              box-sizing: border-box;
              border-radius: 8px;
            }
          }
          input[type='time'] {
            width: 100%;
            height: 3rem;
            background: #f6f6f6;
            opacity: 0.5;
            border: 1px solid $black;
            box-sizing: border-box;
            border-radius: 15px;
            padding-left: 16px;
            background: #ffffff;
            border: 1px solid #cacaca;
            box-sizing: border-box;
            border-radius: 8px;
          }
          textarea {
            width: 100%;
            outline: #f6f6f6;
            opacity: 0.5;
            border: 1px solid $black;
            padding-left: 16px;
            background: #ffffff;
            border: 1px solid #cacaca;
            box-sizing: border-box;
            border-radius: 8px;
          }
          .btn-group {
            .btn {
              &.btn-secondary:not(:disabled):not(.disabled).active {
                background-color: #0077c0;
              }
            }
          }
        }
      }
      .sv_nav {
        margin-top: 55px;
        .sv_complete_btn {
          width: 100%;
          border-radius: 100px;
          border: none;
          padding: 16px 32px;
          background: #0ac2a2;
          border-radius: 8px;
        }
        .sv_next_btn {
          width: 100%;
          border-radius: 100px;
          border: none;
          padding: 16px 32px;
          background: #0ac2a2;
          border-radius: 8px;
        }
      }
    }
    .sv_completed_page {
      display: none;
    }
  }
}
