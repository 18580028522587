@import "~react-bootstrap-toggle/dist/bootstrap2-toggle.css";
.btn.toggle {
  background-color: $white;
  border-color: $gray-400;

  .toggle-handle {
    background-color: $gray-400;
    border-color: $gray-200;
  }
}
