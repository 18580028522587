.validation_error {
    border: 1px solid red !important;
}

.gender-input-dropdown {
    .css-1epf19t-control {
        border: 1px solid #ced4da;
        border-color: #ced4da;
        border-radius: 0.25rem;

    }

    .css-1epf19t-control:hover {
        border-radius: 0.25rem;
    }


}