.container {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .panel-body > div:nth-child(1) {
      padding: 0 10px;
    }

    .sv-action-bar {
      padding: 10px 25px !important;
    }

    .sv_container .card-header {
      background-color: white !important;
      border-bottom: none !important;
      padding-bottom: 0 !important;

      h3 {
        margin-bottom: 0 !important;
      }

      .sv_header__text .sv-string-viewer {
        font-weight: 700 !important;
        padding: 0 5px !important;
        font-family: 'ArialB', sans-serif !important;
      }
    }
}
