@import '~bourbon/core/bourbon';

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'node_modules/bootstrap/scss/bootstrap';
@import './colors';

@import 'overrides/bootstrap-override.scss';

@import 'components/toggle';
@import 'components/icon-button';
@import 'components/ectosense-button';
@import 'components/forms';
@import 'components/pagination';

@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  src:
    local('Arial'),
    url('../assets/fonts/ARIAL.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  src:
    local('Arial'),
    url('../assets/fonts/ARIALN.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  src:
    local('Arial'),
    url('../assets/fonts/ARIALBD.woff') format('woff');
}

@font-face {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 700;
  src:
    local('Arial'),
    url('../assets/fonts/ARIALNB.woff') format('woff');
}

body {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  background: $background-color;

  #root {
    height: 100%;
    .App {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  .ui.modal {
    position: static;
  }

  .navbar {
    z-index: 1050;
  }
}

.container.content {
  margin-bottom: 70px; // Make room for the Feedback button
}

.button-row {
  display: flex;
  > *:not(:first-child) {
    margin-left: 0.5em;
  }
}

.modal {
  > .close.icon {
    top: 0.5rem;
    right: 0.5rem;
    color: $gray;
  }
}
