$brand-primary: rgb(88, 199, 180);
$brand-secondary: #566463;

$blue: #2ea1f8;
$white: #ffffff;
$black: #000000;
$dark: #566463;

$cyan: #83c8bc;
$cyan-dark: #566663;
$cyan-medium: #758985;
$cyan-light: #cae7e2;

$gray: #7f8fa4;
$gray-light: #dfe3eb;
$gray-lighter: #f8fafc;

$subtle-grey: #f5f8fa;

$orange: #ffae75;
$red: #d25459;

$background-color: #eff3f6;
$background-color: $white;

$suspended: $orange;
