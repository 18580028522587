@import '../../../../styles/colors';

.assessment_home {
  padding-top: 4rem;
  padding-bottom: 4rem;

  .intro {
    margin-bottom: 2rem;
  }

  form {
    max-width: calc(100vw - 30px);

    .date-input {
      width: 100%;
      display: flex;

      .date-input-year {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;

        &.is-invalid {
          background-image: none;
        }
      }

      .date-input-day {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left-width: 0;
      }

      .date-input-year,
      .date-input-day {
        width: auto;
        flex-shrink: 0;

        &.is-invalid {
          padding-right: 12px;
        }
      }

      .date-input-month {
        flex: 1;

        &.is-invalid > div {
          border-top-color: #dc3545;
          border-bottom-color: #dc3545;
        }
      }
    }

    h2 {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .buttons {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .rangeslider {
      border-radius: 4px;
      box-shadow: none;
      height: 8px;
      background-color: $cyan;

      .rangeslider__fill {
        background-color: $cyan;
        box-shadow: none;
      }

      .rangeslider__handle {
        box-shadow: none;
        outline: none;

        .rangeslider__handle-label {
          user-select: none;
          outline: none;
          padding: 3px 10px;
        }

        &:after {
          content: none;
        }
      }
    }

    .fulfillment-row {
      margin: 10px -15px -5px;
    }

    .address-row {
      overflow: hidden;
      transition: max-height 0.7s;
      max-height: 0;

      &.expanded {
        height: auto;
        max-height: 500px;
      }
    }

    .about-row {
      > div {
        transition: max-height 0.4s;
        height: auto;
        max-height: 100px;

        &.collapsed {
          max-height: 0;
          overflow: hidden;
        }
      }
    }
  }

}

.assessment_questionaire {
  .questionaire_header {
    background-color: $background-color;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem 0;
    z-index: 1000;

    .range_slider_container {
      padding: 10px;

      .range_slider_background {
        width: 100%;
        display: flex;
        background-color: $gray-light;
        height: 1rem;
      }

      .range_slider_progress {
        background-color: $brand-primary;
        height: 100%;
      }
    }
  }

  .questionaire_body {
    padding-top: 8rem;
    padding-bottom: 10rem;

    .question_container {
      margin-bottom: 3rem;

      &.sub-active {
        opacity: 0.4;
        pointer-events: none;
      }

      .answer-choices {
        text-align: right;
      }

      .question {
        margin-bottom: 1rem;
        background: #fff;
        padding: 1.5rem;
        line-height: 20px;
        border-radius: 16px;
        min-height: 52px;
        display: flex;
        align-items: center;
        font-size: 17px;
        letter-spacing: 0.3px;
        text-align: left;
        font-weight: 400;
        color: gray;


      }

      .cst_no_input {
        margin-top: 0;
      }

      .cst_input {
        .input_number {
          margin: 0 0 0 5px;
          display: inline-block;
          min-width: 100px;
          background: #fff;
          color: #4a4a4a;
        }

        .width_half {
          width: 46px;
        }

        .modify_control {
          display: block;
          width: 100%;
          padding: 0.375rem 0.75rem;
          font-size: 18px;
          line-height: 1.5;
          height: 60px;
          color: #000000;
          background-color: #fff;
          background-clip: padding-box;
          border: 1px solid #979797;
          border-radius: 12px;
          transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }
      }

      .range-slider-container {
        padding: 0 10px;
        margin-bottom: 20px;
        output {
          font-size: 17px;
        }
      }

      .btn {
        margin: 0.3rem;
      }
    }
  }

  .spinner-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 2000;
  }
}

.assessment_results {
  padding-top: 20px;
  padding-bottom: 20px;

  .result_card {
    background-color: white;
    padding: 20px 30px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    min-height: 100%;

    .title {
      font-size: 24px;
      line-height: 30px;
      font-weight: bold;
    }

    .severity_ring_container {
      position: relative;
      padding: 20px;
      margin-bottom: 10px;
    }

    .severity_ring_text {
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%, 0);
    }

    .severity_ring {
      padding: 10%;
    }

  }
}
